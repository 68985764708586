import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios'; // Using axios directly instead of api import
import '../Coursedetails.css';

function CourseDetailPage() {
  const { courseId } = useParams();
  const navigate = useNavigate();

  const [courseDetails, setCourseDetails] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const access_token = userInfo ? userInfo.access : null;

  // Create baseURL for API calls
  const baseURL = 'https://api.andrewslearning.com'; // Update with your actual API base URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const config = access_token ? {
          headers: { Authorization: `Bearer ${access_token}` },
        } : {};

        // Get course details
        const response = await axios.get(`${baseURL}/api/courses/${courseId}`, config);
        setCourseDetails(response.data);

        // Get assessments
        const assessmentsResponse = await axios.get(`${baseURL}/api/courses/${courseId}/assessments`, config);
        setAssessments(assessmentsResponse.data);

        // Get lectures
        const lecturesResponse = await axios.get(`${baseURL}/api/courses/${courseId}/lectures`, config);
        setLectures(lecturesResponse.data);
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching course data:', err);
        setError('Failed to load course data. Please try again later.');
        setLoading(false);
      }
    };
    
    fetchData();
  }, [courseId, access_token]);

  async function startAssessment(assessment) {
    if (!access_token) {
      console.log('Must be logged in to start assessments');
      return;
    }

    try {
      const config = {
        headers: { Authorization: `Bearer ${access_token}` },
      };

      const response = await axios.post(`${baseURL}/api/start_assessment/${assessment.id}/`, {}, config);

      if (response.status === 200) {
        if (assessment.CAT) {
          navigate(`/catassessments/${assessment.id}`, { state: { assessmentResponseId: response.data.assessment_response_id } });
        } else {
          navigate(`/assessments/${assessment.id}`, { state: { assessmentResponseId: response.data.assessment_response_id } });
        }
      }
    } catch (err) {
      console.error('Error starting assessment:', err);
      setError('Failed to start assessment. Please try again.');
    }
  }

  function getEmbedUrl(url) {
    try {
      // Handle different formats of YouTube URLs
      if (!url) return '';
      
      // Handle youtu.be format
      if (url.includes('youtu.be')) {
        const videoId = url.split('youtu.be/')[1].split('?')[0];
        return `https://www.youtube.com/embed/${videoId}`;
      }
      
      // Handle youtube.com format
      if (url.includes('v=')) {
        let videoId = url.split('v=')[1];
        const ampersandPosition = videoId.indexOf('&');
        if (ampersandPosition !== -1) {
          videoId = videoId.substring(0, ampersandPosition);
        }
        return `https://www.youtube.com/embed/${videoId}`;
      }
      
      // If other format or not YouTube, return as is
      return url;
    } catch (error) {
      console.error('Error parsing video URL:', error);
      return '';
    }
  }

  if (loading) {
    return <div className="loading-container">Loading course details...</div>;
  }

  if (error) {
    return <div className="error-container">{error}</div>;
  }

  if (!courseDetails) {
    return <div className="error-container">Course not found. <button onClick={() => navigate('/Courses')}>Back to Courses</button></div>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <center>
            <h1>{courseDetails.title}</h1>
            <p>{courseDetails.description}</p>
          </center>

          {lectures && lectures.length > 0 ? (
            lectures.map((lecture, index) => (
              <div
                key={index}
                className="video-frame"
                style={{ display: currentVideo === index ? 'block' : 'none' }}
              >
                <h3 className="text-center">{lecture.title}</h3>
                {lecture.video ? (
                  // If a video URL is present, embed the YouTube video
                  <div className="video-container">
                    <center>
                      <iframe
                        src={getEmbedUrl(lecture.video)}
                        title="Embedded Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        width="800"
                        height="450"
                      ></iframe>
                    </center>
                  </div>
                ) : access_token ? (
                  // If a video URL isn't present but the user is signed in, show an image
                  <div className="image-container">
                    <center>
                      <img 
                        src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Coming_soon+copy.png" 
                        alt="Content Coming Soon"
                        width="800"
                        height="450"
                      />
                    </center>
                  </div>
                ) : (
                  // If a video URL isn't present and the user isn't signed in, show a sign-in prompt
                  <div className="sign-in-prompt">
                    <h2>Sign in for video lectures, assessments, and notes</h2>
                    <button onClick={() => navigate('/login')}>Sign In</button>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="no-lectures">
              <p>No lectures available for this course yet.</p>
            </div>
          )}

          <div className="assessments-container">
            <h2>Assessments</h2>
            {assessments && assessments.length > 0 ? (
              <ul className="assessments-list">
                {assessments.map(assessment => (
                  <li key={assessment.id} className="assessment-item">
                    <span>{assessment.name}</span>
                    <button className="start-assessment-button" onClick={() => startAssessment(assessment)}>Start</button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No assessments available for this course yet.</p>
            )}
          </div>
        </div>
        <div className="col-lg-4 col-md-12">
          <br/>
          <br/>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title text-center">Lectures</h3>
            </div>
            <div className="card-body playlist-container">
              <div className="list-group">
                {lectures && lectures.length > 0 ? (
                  lectures.map((lecture, index) => (
                    <button
                      key={index}
                      className="list-group-item list-group-item-action"
                      onClick={() => setCurrentVideo(index)}
                    >
                      {lecture.title}
                    </button>
                  ))
                ) : (
                  <p className="text-center">No lectures available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
  );
}

export default CourseDetailPage;
