import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';
import { Nav, Container, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { logout, getUserDetails, updateOnlineUsers } from './actions/userActions';
//import store from './store';
import HomePage from './components/HomePage';
import BlogList from './components/BlogList';
import BlogPost from './components/Blog';
import AllCalculators from './components/AllCalculators';
import MathComponent from './components/Algebralab';
import FunctionGraph from './components/Algebralab3';
import SolveEquation from './components/Algebralab4';
import SolveEquationSteps from './components/Algebralab5';
import Sketch from './components/Physicslab1';
import MomentumSketch from './components/Physicslab2';
import WaveSketch from './components/Physicslab3';
import RefractionSketch from './components/Physicslab4';
import PeriodicTable from './components/Chemlab';
import LoginForm from './components/Login';
import RegisterForm from './components/Registration';
import Register from './components/Register';
import SocialMedia from './components/SocialMedia';
import Header from './components/Header';
import Footer from './components/Footer';
import WaveInterference from './components/Physicslab5';
//import LockdownBrowser from './components/Lockdownbrowser';
//import Exam from './components/Exam';
import CoursesPage from './components/Courses';
import AssessmentPage from './components/AssesmentPage';
import CourseDetailPage from './components/CourseDetails';
import DrawingBoard from './components/Whiteboard';
import SetSimulator from './components/Algebralab2';
import Profile from './components/Profile';
import Cart from './components/Cart';
import UnitConversion from './components/UnitConversionlab';
import CATAssessmentPage from './components/CATassessmentpage';
import LoopVisualizer from './components/Loop';
import InteractiveLabs from './components/InteractiveLabs';
import AdaptiveAssessments from './components/adaptive_lists_assessments.js';
import QuestionCreateForm from './components/createQuestion';
import Checkout from './components/Checkoutpage';
import AssessmentReviewPage from './components/Assessmentresponse';
import RegularAssessmentReview from './components/RegularAssessmentReview';
import ContactUsPage from './components/Contactus';
import AboutUs from './components/AboutUs';
import MoleculeViewer from './components/MoleculeViewer';
import VideoList from './components/Videolist';
import MatrixM from './MatrixMultiplication';
import VideoDetail from './components/Video';
import MolecularEditor from './components/chem';
import ThreeComponent from './Bohr_model_three';
import MatrixMultiplication from './MatrixMultiplication';
import LabThreeComponent from './lab_three_component';
import useSound from './Sound';
import YuhComponent from './test';
import YuhloaderComponent from './loadertest';
import AnatomyThreeScene from './components/AnatomyThree';
import ChatArea from './components/ChatArea';
import Chatapp from './components/Screens/chatapp';
import Chatapplocal from './components/Screens/chatapplocal';
import MachineLearningVisualization from './components/MLfix';
import LiveQuizPage from './components/Screens/LiveQuizPage';
import CodeEditor from './CodeEditor';
import Flashcard from './components/Notecards';
import FlashcardStudyApp from './components/FlashCardStudyApp';
import QuizCase from './components/Screens/QuizCase';
import InviteLink from './components/InviteGuests';
import InviteQuizCase from './components/Screens/InviteQuizCase';
import CookieConsent from './components/cookiesComponent';
import PageVisitTracker from './components/PageVisitTracker';
import QuizCaseorgo from './components/Screens/Organic_nomenclature_game.js';
import Arcade from './components/Games';
import MoleculeEditorFromScratch from './components/Screens/MoleculesScratch';
import Stereocentergame from './components/Screens/Stereocenter_game';
import AnatomyInviteCase from './components/AnatomyInviteCase';
import AnatomyRegularCase from './components/AnatomyCase';
import AnatomyInviteCase2 from './components/AnatomyInviteCase2.js';
import MarvinJSEditor from './components/Chem2.js';
import QuizCaseDrugDiscovery from './components/Screens/DrugDiscoveryGame.js';
import QuizCaseDosages from './components/Screens/Dosage.js';
import QuizKinematics from './components/Screens/kinematicsquizcase.js';
import QuizElectrostatics from './components/Screens/Electrostaticsquizcase.js';
import BiochemReactionGame from './components/Screens/Biochemgame.js'
import PDBScene from './components/MoleculeViewerManual.js';
import MoleculeViewer2 from './components/MoleculeViewerManualCDN.js';
import AcidBaseScene from './components/acidbasechem.js';
import PrivateChat from './components/PrivateChat';
import FounderLetter from './components/FounderLetter';
import QAScreen from './QAScreen';
import ChatAssistant from './components/ChatAssistant';

// Route change tracking component
const RouteChangeTracker = () => {
  const location = useLocation();
  
  useEffect(() => {
    // This component will re-render on route changes
    // We don't need to do anything here as PageVisitTracker handles the tracking
  }, [location]);
  
  return <PageVisitTracker pageName={location.pathname} />;
};

function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const dispatch = useDispatch();
  
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const {playClickSound,stop,loop} = useSound('/ui-click-43196.mp3'); // Local file path
  
  // State for chat assistant - initialize from localStorage if available
  const [isChatAssistantOpen, setChatAssistantOpen] = useState(() => {
    const savedMinimized = localStorage.getItem('assistantMinimized');
    // If assistantMinimized is 'false', that means the chat is open
    return savedMinimized === 'false';
  });

  // Toggle chat assistant
  const toggleChatAssistant = (isOpen) => {
    setChatAssistantOpen(isOpen);
  };

  useEffect(() => {
    if (!user || !user.id) {
      const userId = userInfo ? userInfo.id : null;
      if (userId) {
        dispatch(getUserDetails(userId));
      }
    }
  }, [dispatch, userInfo, user]);

  // State and references for user status WebSocket (kept separate from the shared WebSocket)
  const [onlineUsers, setOnlineUsers] = useState([]); // State to track online users
  const statusWs = useRef(null); // Use a ref to store WebSocket instance for user status

  useEffect(() => {
    if (userInfo) {
      statusWs.current = new WebSocket(`wss://api.andrewslearning.com/ws/onlinestatus/`);
  
      statusWs.current.onopen = () => {
        // Notify server of user connection
        statusWs.current.send(JSON.stringify({ type: 'user_connected', username: userInfo.username }));
      };
  
      statusWs.current.onmessage = (event) => {
        try {
          const messageData = JSON.parse(event.data);

          if (messageData.type === 'ping') {
            // Send 'pong' response to server
            statusWs.current.send(JSON.stringify({ type: 'pong' }));
            console.log('Pong sent in response to ping');
          } else if (messageData.type === 'user_status_change') {
            // Handle user status changes
            dispatch(updateOnlineUsers(messageData.username, messageData.online));
          }
        } catch (error) {
          console.error('Error parsing message data:', error);
        }
      };
  
      return () => {
        if (statusWs.current) statusWs.current.close();
      };
    }
  }, [userInfo, dispatch]);

  // Log out handler
  const logoutHandler = () => {
    dispatch(logout());
    if (statusWs.current) {
      statusWs.current.close(); // Close WebSocket on logout
    }
    
    // Also close the shared WebSocket if it exists
    if (window.sharedWebSocket) {
      window.sharedWebSocket.close();
      window.sharedWebSocket = null;
    }
  };

  // Calculate the number of items in the cart
  const cartItemCount = cartItems.length;

  return (
    <Router>
      <CookieConsent />
      <PageVisitTracker />
      <SocialMedia />
      
      {/* The Header component now includes the new navbar */}
      <Header />

      <Routes>
        <Route path="/qa" element={<QAScreen />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/videos" element={<VideoList/>} />
        <Route path="/" element={<HomePage />} />
        <Route path="/Blog" element={<BlogList />} />
        <Route path="/Whiteboard" element={<DrawingBoard />} />
        <Route path="/posts/:postId" element={<BlogPost />} />
        <Route path="/InteractiveLabs" element={<InteractiveLabs />} />
        <Route path="/Calculators" element={<AllCalculators />} />
        <Route path="/Algebralab" element={<MathComponent />} />
        <Route path="/Algebralab2" element={<SetSimulator />} />
        <Route path="/Algebralab3" element={<FunctionGraph />} />
        <Route path="/Algebralab4" element={<SolveEquation />} />
        <Route path="/Algebralab5" element={<SolveEquationSteps />} />
        <Route path="/Unit Conversions" element={<UnitConversion />} />
        <Route path="/Physicslab1" element={<Sketch />} />
        <Route path="/Physicslab2" element={<MomentumSketch />} />
        <Route path="/Physicslab3" element={<WaveSketch />} />
        <Route path="/Physicslab4" element={<RefractionSketch />} />
        <Route path="/Physicslab5" element={<WaveInterference />} />
        <Route path="/Physicslab5" element={<WaveInterference />} />
        <Route path="/MoleculeViewer" element={<MoleculeViewer />} />
        <Route path="/Chemlab" element={<PeriodicTable />} />
        <Route path="/Login" element={<LoginForm />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Courses" element={<CoursesPage />} />
        <Route path="/courses/:courseId" element={<CourseDetailPage />} />
        <Route path="/assessments/:assessmentId" element={<AssessmentPage />} />
        <Route path="/catassessments/:assessmentId" element={<CATAssessmentPage />} />
        <Route path="/Profile/:id" element={<Profile />} />
        <Route path='/Checkout' element={<Checkout />} />
        <Route path='/Programming' element={<LoopVisualizer />} />
        <Route path='/AdaptiveAssessments' element={<AdaptiveAssessments />} />
        <Route path='/QuestionCreate' element={<QuestionCreateForm />} />
        <Route path='/Contact' element={<ContactUsPage />} />
        <Route path='/assessment_review/:assessmentResponseId' element={<AssessmentReviewPage />} />
        <Route path='/regular_assessment_review/:assessmentResponseId' element={<RegularAssessmentReview />} />
        <Route path='/About' element={<AboutUs />} />
        <Route path='/Matrix' element={<MatrixMultiplication />} />
        <Route path='/lectures/:videoId' element={<VideoDetail />} />
        <Route path='/chemstuff' element={<MarvinJSEditor />} />
        <Route path='/Atom' element={<ThreeComponent />} />
        <Route path='/MM' element={<MatrixM />} />
        <Route path='/labcomponent' element={< LabThreeComponent />} />
        <Route path='/test' element={< YuhloaderComponent />} />
        <Route path="/joinanatomy/" element={<AnatomyThreeScene />} />
        <Route path='/Chat' element={<Chatapp />} />
        <Route path='/AdaGPT' element={<Chatapplocal />} />
        <Route path='/ML' element={<MachineLearningVisualization />} />
        <Route path='/code-editor' element={<CodeEditor />} />
        <Route path='/flashcards' element={< Flashcard />} />
        <Route path='/flashcardstudy' element={< FlashcardStudyApp />} />
        <Route path='/FunQuizzes' element={< QuizCase />} />
        <Route path='/invite' element={< InviteLink />} />
        <Route path="/livquiz" element={<LiveQuizPage />} />
        <Route path="/joinquiz/:topics/:roomNumber/" element={<InviteQuizCase />} />
        <Route path="/nomenclature" element={<QuizCaseorgo />} />
        <Route path="/arcade" element={<Arcade />} />
        <Route path="/Mols" element={<MoleculeEditorFromScratch />} />
        <Route path="/stereocentergame" element={<Stereocentergame />} />
        {/* Invite link for shared sessions */}
        <Route path="/joinanatomyinvite" element={<AnatomyInviteCase />} />
        <Route path="/localmoleculeviewer" element={<PDBScene />} />
        {/* Regular anatomy session for logged-in users */}
        <Route path="/anatomy2" element={<AnatomyRegularCase />} />
        <Route path="/anatomy2/:roomNumber/:userId" element={<AnatomyInviteCase2 />} />
        <Route path="/DrugDiscoveryGame" element={<QuizCaseDrugDiscovery />} />
        <Route path="/DosageGame" element={<QuizCaseDosages />} />
        <Route path="/Electrostatics" element={<QuizElectrostatics />} />
        <Route path="/Moleculesagain" element={<MoleculeViewer2 />} />
        <Route path="/Kinematics" element={<QuizKinematics />} />
        <Route path="/Biochem" element={<BiochemReactionGame />} />
        <Route path="/HendersonHasselbalch" element={<AcidBaseScene />} />
        <Route path="/chat" element={<PrivateChat />} />
        <Route path="/videos" element={<VideoList />} />
        <Route path="/video/:videoId" element={<VideoDetail />} />
        <Route path="/founder-letter" element={<FounderLetter />} />
        <Route path="/KinematicsGame" element={<QuizKinematics />} />
        <Route path="/ElectrostaticsGame" element={<QuizElectrostatics/>} />
        <Route path="/BiochemReactionGame" element={<BiochemReactionGame/>} />
        <Route path="/MoleculeViewer" element={<MoleculeViewer2/>} />
        <Route path="/AcidBase" element={<AcidBaseScene/>} />
        <Route path="/PrivateChat/:id" element={<PrivateChat/>} />
        <Route path="/Founder" element={<FounderLetter/>} />
      </Routes>

      {/* Chat Assistant component */}
      <ChatAssistant isOpen={isChatAssistantOpen} onToggle={toggleChatAssistant} />
      
      <RouteChangeTracker />
      <Footer />
    </Router>
  );
}

export default App;
