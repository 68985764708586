import React, { useRef, useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import LanguageSelector from "./LanguageSelector";
import Output from "./Output";
import './CodeEditor.css';

const CodeEditor = () => {
  const [socketConnected, setSocketConnected] = useState(false);
  const editorRef = useRef();
  const [value, setValue] = useState("");
  const [language, setLanguage] = useState("javascript");
  const [languages, setLanguages] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const onMount = (editor) => {
    editorRef.current = editor;
    editor.focus();
  };

  const onSelect = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    setValue(""); // Clear editor when switching languages
    setFeedback(null); // Clear feedback when changing language
  };

  // Check for WebSocket availability and connection status
  useEffect(() => {
    const checkWebSocketStatus = () => {
      const ws = window.sharedWebSocket;
      setSocketConnected(ws && ws.readyState === WebSocket.OPEN);
    };

    // Check immediately and set up an interval to keep checking
    checkWebSocketStatus();
    const intervalId = setInterval(checkWebSocketStatus, 2000);

    // Set up message event listener
    const handleMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        
        // Handle code analysis feedback
        if (data.type === 'code_feedback') {
          setFeedback(data.analysis);
          setIsAnalyzing(false);
        } else if (data.type === 'answer' && data.question && data.question.includes('Analyze this')) {
          // Handle general answers that might be code analysis
          setFeedback(data.answer);
          setIsAnalyzing(false);
        }
      } catch (error) {
        console.error('Error handling WebSocket message:', error);
        setIsAnalyzing(false);
      }
    };

    // Add the event listener to the shared WebSocket if it exists
    if (window.sharedWebSocket) {
      window.sharedWebSocket.addEventListener('message', handleMessage);
    }

    return () => {
      clearInterval(intervalId);
      // Clean up event listener
      if (window.sharedWebSocket) {
        window.sharedWebSocket.removeEventListener('message', handleMessage);
      }
    };
  }, []);

  // Function to send code for analysis
  const analyzeCode = () => {
    if (!window.sharedWebSocket || window.sharedWebSocket.readyState !== WebSocket.OPEN) {
      console.error("WebSocket connection not available");
      alert("WebSocket connection not available. Please connect to the Learning Assistant first.");
      return;
    }

    if (!value.trim()) {
      return; // Don't analyze empty code
    }

    setIsAnalyzing(true);
    setFeedback(null);
    
    // Send the code for analysis
    window.sharedWebSocket.send(JSON.stringify({
      question: `Analyze this ${language} code and provide suggestions for improvement: \n\`\`\`${language}\n${value}\n\`\`\``,
      type: 'code_analysis'
    }));
  };

  useEffect(() => {
    async function fetchRuntimes() {
      try {
        const response = await fetch("https://emkc.org/api/v2/piston/runtimes");
        const data = await response.json();
        const formattedLanguages = data.map(({ language, version }) => [
          language,
          version,
        ]);
        setLanguages(formattedLanguages);
      } catch (error) {
        console.error("Failed to fetch languages:", error);
      }
    }
    fetchRuntimes();
  }, []);

  return (
    <div>
      {/* Instructional Section */}
      <div className="instructional-container">
        <h2 className="instructional-title">Code Editor</h2>
        <p className="instructional-text">
          Use this editor to practice your <strong>programming skills</strong> without needing to install an Integrated Development Environment (IDE). 
          Write and run code directly in your browser across multiple programming languages.
        </p>
        <ul className="instructional-list">
          <li>Select a language from the dropdown menu.</li>
          <li>Write your code in the editor below.</li>
          <li>Click "Run" to execute your code and view the output.</li>
          <li>Click "Analyze Code with AI" to get feedback on your code.</li>
        </ul>
        <p className="instructional-note">
          This editor supports multiple languages like <strong>Python</strong>, <strong>JavaScript</strong>, and <strong>C++</strong>. Happy coding!
        </p>
      </div>
    <div className="code-editor-layout">
      {/* Code Editor and Output Section */}
      <div className="editor-output-container">
        <div className="editor-container">
          <LanguageSelector
            language={language}
            onSelect={onSelect}
            languages={languages}
          />
          <Editor
            height="75vh"
            theme="vs-dark"
            language={language}
            value={value}
            onMount={onMount}
            onChange={(newValue) => setValue(newValue)}
            options={{
              minimap: { enabled: false },
            }}
          />
        </div>
        <div className="output-section">
          <Output editorRef={editorRef} language={language} />
          
          {/* Add a dedicated analysis button container */}
          <div className="analyze-button-container">
            <button 
              className="analyze-button" 
              onClick={analyzeCode}
              disabled={!socketConnected || isAnalyzing || !value.trim()}
            >
              {isAnalyzing ? "Analyzing..." : "Analyze Code with AI"}
            </button>
            {!socketConnected && (
              <div className="websocket-notice">
                Learning Assistant is not connected. Open the Learning Assistant in the bottom right and click "Start Learning Assistant" first.
              </div>
            )}
          </div>
          
          {/* Show AI feedback when available */}
          {feedback && (
            <div className="feedback-container">
              <h3>AI Code Analysis</h3>
              <div className="feedback-content">
                <pre>{feedback}</pre>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default CodeEditor;
