import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Card, Spinner } from 'react-bootstrap';
import './QAScreen.css';

const QAScreen = () => {
  const [connected, setConnected] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [question, setQuestion] = useState('');
  const [conversation, setConversation] = useState([]);
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [roomName, setRoomName] = useState('qaroom');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  
  const socketRef = useRef(null);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  // Auto-scroll to bottom of messages
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation, status]);

  // Mark initial load as complete after first render
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialLoad(false);
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);

  // Focus input after connection is established
  useEffect(() => {
    if (connected && inputRef.current) {
      inputRef.current.focus();
    }
  }, [connected]);

  // Connect to WebSocket
  const connectWebSocket = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      return; // Already connected
    }

    setConnecting(true);
    setError('');
    
    const wsUrl = `wss://api.andrewslearning.com/wschat/QA/andrewbrowne/`;
    socketRef.current = new WebSocket(wsUrl);

    socketRef.current.onopen = () => {
      console.log('WebSocket connection established');
      setConnected(true);
      setConnecting(false);
      setConversation(prev => [...prev, { 
        type: 'system', 
        message: `Connected to AI question answering service in room: ${roomName}`
      }]);
    };

    socketRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received:', data);

      if (data.type === 'system_message') {
        setConversation(prev => [...prev, { type: 'system', message: data.message }]);
      } else if (data.type === 'status') {
        setStatus(data.message);
      } else if (data.type === 'answer') {
        setStatus('');
        setConversation(prev => [
          ...prev, 
          { 
            type: 'answer', 
            question: data.question, 
            answer: data.answer 
          }
        ]);
      } else if (data.type === 'error') {
        setError(data.message);
        setStatus('');
      }
    };

    socketRef.current.onclose = (event) => {
      console.log('WebSocket connection closed', event);
      setConnected(false);
      setConnecting(false);
      if (!event.wasClean) {
        setError('Connection lost. Please reconnect.');
      } else {
        setConversation(prev => [...prev, { 
          type: 'system', 
          message: 'Disconnected from AI service. Connect again to ask more questions.'
        }]);
      }
    };

    socketRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
      setError('Failed to connect. Please try again.');
      setConnecting(false);
    };
  };

  // Disconnect from WebSocket
  const disconnectWebSocket = () => {
    if (socketRef.current) {
      socketRef.current.close();
      setConnected(false);
    }
  };

  // Send a question
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!question.trim() || !connected) return;

    const message = {
      question: question
    };

    socketRef.current.send(JSON.stringify(message));
    setConversation(prev => [...prev, { type: 'question', message: question }]);
    setQuestion('');
    setError('');
  };

  // Clean up WebSocket on component unmount
  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  // Format message with enhanced styling
  const formatMessage = (text) => {
    return text.split('\n').map((line, i) => (
      <span key={i}>
        {line}
        {i < text.split('\n').length - 1 && <br />}
      </span>
    ));
  };

  return (
    <div className={`qa-container ${isInitialLoad ? 'initial-load' : ''}`}>
      <div className="qa-title">
        <h1>AI Question Answering</h1>
        {connected && (
          <div className="connection-indicator">
            <span className="status-dot connected"></span>
            <small>Connected to {roomName}</small>
          </div>
        )}
      </div>
      
      {/* Information section that explains how the QA system works */}
      <div className="qa-info-section">
        <div className="info-container">
          <div className="info-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
            </svg>
          </div>
          <div className="info-content">
            <h2>How This Works</h2>
            <p>
              Our AI question answering system provides reliable information sourced directly from accredited textbooks. 
              When you ask a question, the AI searches through a digital library of academic content to find the most relevant information.
            </p>
            <ul className="info-points">
              <li>
                <span className="info-point-icon">📚</span>
                <span className="info-point-text"><strong>Trusted Sources:</strong> All answers come from verified academic textbooks and professional learning materials.</span>
              </li>
              <li>
                <span className="info-point-icon">🔍</span>
                <span className="info-point-text"><strong>Smart Matching:</strong> The system finds the most relevant information by understanding the meaning of your question.</span>
              </li>
              <li>
                <span className="info-point-icon">🧠</span>
                <span className="info-point-text"><strong>Learning Aid:</strong> Perfect for studying, fact-checking, or exploring new topics with academic reliability.</span>
              </li>
            </ul>
            <p className="info-footnote">
              Ask a specific question about any subject to get started. The more precise your question, the better the answer!
            </p>
          </div>
        </div>
      </div>
      
      <div className="qa-content">
        <Card className="qa-card">
          <Card.Header>
            <Form onSubmit={(e) => {
              e.preventDefault();
              if (!connected) {
                connectWebSocket();
              } else {
                disconnectWebSocket();
              }
            }} className="d-flex">
              <Form.Group className="flex-grow-1 me-2">
                <Form.Control
                  type="text"
                  value={roomName}
                  onChange={(e) => setRoomName(e.target.value)}
                  placeholder="Enter room name"
                  disabled={connected || connecting}
                />
              </Form.Group>
              <Button 
                type="submit" 
                variant={connected ? "danger" : "primary"}
                disabled={connecting}
                className={connecting ? "btn-loading" : ""}
              >
                {connecting ? (
                  <>
                    <Spinner animation="border" size="sm" /> Connecting...
                  </>
                ) : connected ? 'Disconnect' : 'Connect'}
              </Button>
            </Form>
          </Card.Header>
          
          <Card.Body className="conversation-container">
            {conversation.length === 0 && !status && (
              <div className="empty-state">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                </svg>
                <p>Connect and ask a question to start your conversation</p>
              </div>
            )}
            
            {conversation.map((item, index) => (
              <div key={index} className={`message ${item.type}`}>
                {item.type === 'system' && (
                  <div className="system-message">{formatMessage(item.message)}</div>
                )}
                
                {item.type === 'question' && (
                  <div className="user-question">
                    <strong>You</strong> {formatMessage(item.message)}
                  </div>
                )}
                
                {item.type === 'answer' && (
                  <div className="ai-answer">
                    <div className="question"><strong>Q:</strong> {formatMessage(item.question)}</div>
                    <div className="answer"><strong>A:</strong> {formatMessage(item.answer)}</div>
                  </div>
                )}
              </div>
            ))}
            
            {status && (
              <div className="status-message">
                <Spinner animation="border" size="sm" /> {status}
              </div>
            )}
            
            {error && (
              <div className="error-message">
                Error: {error}
              </div>
            )}
            
            <div ref={messagesEndRef} />
          </Card.Body>
          
          <Card.Footer>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="d-flex">
                <Form.Control
                  ref={inputRef}
                  type="text"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder={connected ? "Type your question here..." : "Connect to start asking questions"}
                  disabled={!connected}
                />
                <Button 
                  type="submit" 
                  className="ms-2"
                  disabled={!connected || !question.trim()}
                >
                  Send
                </Button>
              </Form.Group>
            </Form>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

export default QAScreen; 
